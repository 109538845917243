<template>
  <div class="mobile-container">
    <div class="page-header-item">
      <div class="bg-item img-cover"><img src="../../assets/images/user-bg.png" /></div>
      <div class="page-header-content">
        <div class="setting-item">
          <router-link class="btn img-cover" :to="{path: '/setting'}"><img src="../../assets/images/setting-icon.png" /></router-link>
        </div>
        <div class="user-info-item">
          <router-link class="header" :to="{path: '/setting'}">
            <div class="img img-cover">
              <img v-if="userInfo.Header" :src="userInfo.Header" />
              <img v-else src="../../assets/images/default-header.png" />
            </div>
            <div class="vip-icon img-cover">
              <img v-if="userInfo.Icon" :src="userInfo.Icon" />
              <!-- <img v-if="userInfo.Level == 1" src="../../assets/images/vip-icon-1.png" />
              <img v-if="userInfo.Level == 2" src="../../assets/images/vip-icon-2.png" />
              <img v-if="userInfo.Level == 3" src="../../assets/images/vip-icon-3.png" />
              <img v-if="userInfo.Level == 4" src="../../assets/images/vip-icon-4.png" />
              <img v-if="userInfo.Level == 5" src="../../assets/images/vip-icon-5.png" />
              <img v-if="userInfo.Level == 6" src="../../assets/images/vip-icon-6.png" />
              <img v-if="userInfo.Level == 7" src="../../assets/images/vip-icon-7.png" /> -->
            </div>
          </router-link>
          <div class="info">
            <div class="nickname">
              <span v-if="userInfo.Nickname">{{ userInfo.Nickname }}</span>
              <span v-else>{{ userInfo.Phone | phoneFormat }}</span>
            </div>
            <div class="id">ID: {{ userInfo.Id }}</div>
            <div class="phone">手机号: {{ userInfo.Phone }}</div>
          </div>
        </div>
        <div class="flex-1"></div>
        <router-link class="vip-item img-cover" :to="{path: '/vip'}">
          <img src="../../assets/images/vip-bg.png" />
          <div class="vip-inner">
            <div class="icon img-cover"><img src="../../assets/images/vip-icon.png" /></div>
            <div class="name">
              <span class="big">开通会员</span>
              <div class="dot"></div>
              享专属权益
            </div>
            <div class="btn">{{ userInfo.VName || '立即开通' }}</div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="ky-money-item">
      <div class="ky-money-header">
        <div class="money">余额：<span>¥{{ Math.round(userInfo.Balance * 10000) / 10000 }}</span></div>
        <router-link v-if="userInfo.UserIsCz == 1" class="btn" :to="{path: '/balance'}">立即充值</router-link>
      </div>
      <div class="sy-item">
        <div class="item">
          <div class="name">近一周收益统计</div>
          <div class="value">{{ syData.seven | moneyFormat }}</div>
        </div>
        <div class="item">
          <div class="name">昨日收益统计</div>
          <div class="value">{{ syData.yesterday | moneyFormat }}</div>
        </div>
      </div>
    </div>
    <div class="column-item">
      <div class="title-item">功能专区</div>
      <div class="list-item">
        <router-link class="item" :to="{path: '/order'}">
          <div class="icon img-contain"><img src="../../assets/images/user-dd-icon.png" /></div>
          <div class="name">我的订单</div>
        </router-link>
        <router-link class="item" :to="{path: '/balanceRecord'}">
          <div class="icon img-contain"><img src="../../assets/images/user-sz-icon.png" /></div>
          <div class="name">收支明细</div>
        </router-link>
        <router-link class="item" :to="{path: '/gd'}">
          <div class="icon img-contain"><img src="../../assets/images/user-gd-icon.png" /></div>
          <div class="name">工单明细</div>
        </router-link>
        <router-link class="item" :to="{path: userInfo.FzLevel >= 50 ? '/website' : '/buildSite'}">
          <div class="icon img-contain"><img src="../../assets/images/user-fz-icon.png" /></div>
          <div class="name">分站管理</div>
        </router-link>
        <router-link class="item" :to="{path: '/collection'}">
          <div class="icon img-contain"><img src="../../assets/images/user-sc-icon.png" /></div>
          <div class="name">我的收藏</div>
        </router-link>
        <router-link class="item" :to="{path: '/people'}">
          <div class="icon img-contain"><img src="../../assets/images/user-tg-icon.png" /></div>
          <div class="name">我的推广</div>
        </router-link>
        <router-link class="item" :to="{path: '/secret'}">
          <div class="icon img-contain"><img src="../../assets/images/user-my-icon.png" /></div>
          <div class="name">密钥管理</div>
        </router-link>
      </div>
    </div>
    <div class="column-item" style="margin-bottom: .8rem;">
      <div class="title-item">其他服务</div>
      <div class="list-item">
        <router-link class="item" :to="{path: '/contact'}">
          <div class="icon small img-contain"><img src="../../assets/images/user-kf-icon.png" /></div>
          <div class="name">联系客服</div>
        </router-link>
        <router-link v-if="showSstz" class="item" :to="{path: '/notice'}">
          <div class="icon small img-contain"><img src="../../assets/images/user-xx-icon.png" /></div>
          <div class="name">消息通知</div>
        </router-link>
      </div>
    </div>
    <mobileTabBar :tabBarKey="tabBarKey" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mobileTabBar from '@/components/mobileTabBar'

export default {
  name: "User",
  components: {
    mobileTabBar
  },
  data() {
    return {
      tabBarKey: 'user',
      syData: {},
      showSstz: false
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  filters: {
    phoneFormat(text) {
      if (text) {
        return text.substr(0, 3) + '****' + text.substr(-4)
      }
      return ''
    },
    moneyFormat(text) {
      if (text) {
        return Math.round(parseFloat(text) * 100) / 100
      }
      return 0
    }
  },
  watch: {
    
  },
  mounted() {
    this.handleGetSyTj()
    this.handleGetSetting()
  },
  methods: {
    async handleGetSetting() {
      var res = await this.$API.getSet.post({
        'Key': 'cz_show'
      })
      const { userInfo } = this
      if (userInfo && userInfo.Id) {
        if (userInfo.UserIsSs == 1 && parseFloat(userInfo.AllMoney) >= parseFloat(res.info)) {
          this.showSstz = true
        } else {
          this.showSstz = false
        }
      } else {
        if (res.info == -1) {
          this.showSstz = true
        } else {
          this.showSstz = false
        }
      }
    },
    async handleGetSyTj() {
      const res = await this.$API.syTj.post()
      this.syData = res.info
    }
  },
};
</script>

<style lang="less" scoped>
.page-header-item {
  position: relative;
  width: 100%;
  height: 3.62rem;
  overflow: hidden;
  .bg-item {
    position: absolute;
    left: 0;
    // top: 0;
    right: 0;
    bottom: 0;
    height: 4.48rem;
  }
  .page-header-content {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 3.62rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .setting-item {
      padding: .22rem .32rem .06rem 0;
      display: flex;
      justify-content: flex-end;
      height: .44rem;
      .btn {
        width: .44rem;
        height: .44rem;
      }
    }
    .user-info-item {
      padding: 0 .32rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .header {
        position: relative;
        width: 1.2rem;
        height: 1.2rem;
        .img {
          border: .02rem solid #fff;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          overflow: hidden;
        }
        .vip-icon {
          position: absolute;
          bottom: -.12rem;
          left: 50%;
          transform: translateX(-50%);
          width: 1.12rem;
          height: .36rem;
        }
      }
      .info {
        width: 5.42rem;
        color: #11113a;
        line-height: .34rem;
        font-size: .24rem;
        .nickname {
          line-height: .48rem;
          font-size: .32rem;
          font-weight: bold;
        }
      }
    }
    .vip-item {
      margin: 0 auto;
      width: 6.5rem;
      height: 1.08rem;
      position: relative;
      .vip-inner {
        position: absolute;
        top: 0;
        left: .24rem;
        right: .24rem;
        height: 1.08rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon {
          width: .32rem;
          height: .32rem;
        }
        .name {
          padding: 0 .12rem;
          flex: 1;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-size: .24rem;
          color: #7b7e91;
          .big {
            font-size: .28rem;
            font-weight: bold;
            color: #f7d9ba;
          }
          .dot {
            width: .08rem;
            height: .08rem;
            margin: 0 .08rem;
            background-color: #7b7e91;
            border-radius: 50%;
          }
        }
        .btn {
          width: 1.44rem;
          height: .52rem;
          border-radius: .26rem;
          text-align: center;
          line-height: .52rem;
          color: #614329;
          font-size: .24rem;
          font-weight: 500;
          background: linear-gradient( 120deg, #ffe3bb 0%, #ffdebf 100%);
          box-shadow: inset 0 0 .06rem .02rem #fff;
        }
      }
    }
  }
}
.ky-money-item {
  margin: .32rem auto 0;
  width: 6.86rem;
  height: 2.2rem;
  border-radius: .24rem;
  background: linear-gradient( 62deg, #1399ff 0%, #97d2ff 100%);
  box-shadow: 0 0 .24rem .02rem rgba(19, 153, 255, .16);
  display: flex;
  flex-direction: column;
  align-items: center;
  .ky-money-header {
    width: 100%;
    height: .8rem;
    padding: 0 .24rem;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .money {
      font-size: .24rem;
      color: #fff;
      span {
        font-weight: bold;
        font-size: .36rem;
      }
    }
    .btn {
      width: 1.44rem;
      height: .52rem;
      border-radius: .26rem;
      background-color: #fff;
      box-shadow: 0 0 .08rem .02rem rgba(19, 153, 255, .6);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: .24rem;
      font-weight: 500;
      color: #1399ff;
    }
  }
  .sy-item {
    width: 6.82rem;
    height: 1.38rem;
    background-color: #fff;
    border-radius: .24rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .item {
      flex: 1;
      height: 1.38rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      .name {
        margin-top: .08rem;
        font-size: .24rem;
        color: #b3b3b3;
        line-height: .34rem;
      }
      .value {
        line-height: .48rem;
        font-size: .4rem;
        font-weight: bold;
        color: #151d2b;
      }
    }
  }
}
.column-item {
  padding: .32rem 0 .16rem .4rem;
  .title-item {
    line-height: .42rem;
    font-size: .3rem;
    color: #11113a;
    font-weight: bold;
  }
  .list-item {
    overflow: hidden;
    .item {
      margin-top: .32rem;
      margin-right: .48rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      float: left;
      width: 1.32rem;
      height: 1.34rem;
      &:nth-child(4n) {
        margin-right: 0;
      }
      .icon {
        width: .92rem;
        height: .92rem;
        &.small {
          width: .6rem;
          height: .6rem;
        }
      }
      .name {
        margin-top: .08rem;
        line-height: .34rem;
        color: #151d2b;
        font-size: .24rem;
      }
    }
  }
}
</style>